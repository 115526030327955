import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col, Alert } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesMetaSynchroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Synchro Device Trick</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_2.png"
            alt="Early and late meta teams"
          />
        </div>
        <div className="page-details">
          <h1>Synchro Device Trick</h1>
          <h2>
            A great trick that will help you in the early game when you have no
            dupes.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Video version" />
        <p>For those that prefer watching a video version:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="CeAhNQQZgLQ" />
          </Col>
        </Row>
        <SectionHeader title="What is Synchro Device?" />
        <StaticImage
          src="../../../images/nikke/generic/synchro.jpg"
          alt="Synchro"
        />
        <p>
          Synchro Device is a system that will allow you to boost a NIKKE’s
          level to match the highest 5 leveled characters you have. This makes
          it so you don’t have to worry about leveling any new NIKKE’s you
          obtain that may suit your preferences and needs!
        </p>
        <p>
          Now this may get confusing so pay attention! The current 5 highest
          leveled NIKKEs will be shown in the menu, where the lowest level of
          the NIKKE will be chosen in order to determine the current level you
          can boost your other NIKKEs to. If they are all equal level, then the
          equal level instead will be chosen.
        </p>
        <p>You can access the option in your Outpost.</p>
        <SectionHeader title="The trick" />
        <Alert variant="primary">
          <Alert.Heading>Important!</Alert.Heading>
          <p>
            You no longer need to do the trick described below because with the
            1.09.2023 update, the max level limits have been changed - now all
            0-2 dupe characters, not matter their rarity can reach the 160 level
            cap, so you no longer need to level your SR characters first.
          </p>
        </Alert>
        <p>
          One thing that the in-game description of this feature doesn't tell
          you is that whenever you place a character inside the Synchro device
          (but not as one of the top 5 characters),{' '}
          <strong>she will be leveled beyond her level cap</strong>.
        </p>
        <p>
          This means that the single copy of Scarlet or Harran that you rerolled
          for, won't be stuck on level 80 (the initial level cap) until you get
          a dupe. Instead, you will use all your resources on 5x SR characters -
          getting dupes for them is super easy and with just 2, you can increase
          their level cap from 80 to 160 (they can't go further using 'normal'
          means). Then you throw your shiny SSR into the Synchro device and it
          will be leveled up to the lowest level of your top 5 - even beyond the
          level cap!
        </p>
        <p>
          Also, you don't waste any resources leveling those SR, because at any
          moment, just for the cost of 10 Gems (300 is one pull) you can reset
          them and get back 100% materials you used!
        </p>
        <p>So here's the TL;DR for this method:</p>
        <ul>
          <li>
            At the start play the game normally and level the top 5 characters
            you actually use in combat - do this until you start hitting the
            level cap on them (level 80),
          </li>
          <li>
            Now, reset those 5 characters and instead level the 5 SR rarity ones
            that you have the most dupes of - getting SR is actually easy,
          </li>
          <li>
            Throw the characters you actually want to use in combat to the
            Synchro Device and they will be leveled to match the level of your
            top 5 character,
          </li>
          <li>
            Once any of your SSR get its 3rd dupe, which means you can increase
            the leval cap of hers to 200, do the switch again and use her as one
            of the top 5.
          </li>
          <li>
            Repeat this until you get 5 characters to level 200. Then the
            Synchro Device will evolve and now you will just level it instead.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMetaSynchroPage;

export const Head: React.FC = () => (
  <Seo
    title="Synchro Device Trick | NIKKE | Prydwen Institute"
    description="A great trick that will help you in the early game when you have no dupes."
  />
);
